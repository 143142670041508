<template>
  <div>
    <div v-if="instanceList === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div
      v-if="instanceList !== null && !instanceList.length"
      class="text--secondary"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon size="64">
              mdi-alert-circle-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            {{ $t('noPaymentMethodsDefinedForSelectedOrganization') }}
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="instanceList !== null && instanceList.length">
      <v-list>
        <template v-for="(instance, index) in instanceList">
          <PaymentMethodInstanceListItem
            :key="instance.uuid"
            :payment-method="instance"
            v-on="$listeners"
          />
          <v-divider
            :key="index"
            inset
            v-if="index-1 < instanceList.length"
          />
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import PaymentMethodInstanceListItem from "./PaymentMethodInstanceListItem"

export default {
	name: "PaymentMethodInstanceList",
	components: {
		PaymentMethodInstanceListItem
	},
	props: {
		instanceList: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped>

</style>
