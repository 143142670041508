<template>
  <v-list-item
    @click="paymentRequest"
    :disabled="!paymentMethod.status"
  >
    <v-list-item-avatar>
      <v-avatar class="grey">
        <v-img
          v-if="paymentMethod.icon"
          :src="paymentMethod.icon"
        />
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ paymentMethod.title }}
        <span v-if="false">
          <v-btn
            x-small
            v-if="paymentMethod.status"
            color="success"
          >{{ $t('enabled') }}</v-btn>
          <v-btn
            x-small
            v-else
            color="error"
          >{{ $t('disabled') }}</v-btn>
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ paymentMethod.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: "PaymentMethodInstanceListItem",
	props: {
		paymentMethod: {
			type: Object,
			default: () => {
			}
		}
	},
	methods: {
		paymentRequest() {
			this.$emit('selected', this.paymentMethod)
		}
	}
}
</script>

<style scoped>

</style>
