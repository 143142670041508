<template>
  <v-dialog
    max-width="800"
    :value="value"
    @click:outside="abort"
  >
    <v-card>
      <v-card
        v-if="false"
        tile
        :color="primaryColor"
        :dark="isDark(primaryColor)"
      >
        <v-card-title>
          {{ $t('selectPaymentMethod') }}
        </v-card-title>
      </v-card>
      <PaymentMethodInstanceList
        :instance-list="sortedList"
        v-on="$listeners"
      />
      <v-card-actions
        class="grey lighten-1"
        v-if="false"
      >
        <v-btn @click="abort">
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PaymentMethodInstanceList from "./PaymentMethodInstanceList"

export default {
	name: "PaymentMethodInstanceDialog",
	components: {PaymentMethodInstanceList},
	props: {
		value: {
			type: Boolean
		},
		instanceList: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		abort() {
			this.$emit('aborted', true)
		},
		isDark(color) {
			return !this.$store.getters.colorIsLight(color)
		},
		sortedInstanceList() {
			if (this.instanceList && this.instanceList.length) {
				return this.instanceList.sort((a, b) => a.status - b.status)
			} else {
				return this.instanceList
			}
		}
	},
	computed: {
		sortedList() {
			let list = this.instanceList
			// Started by sorting by status but now we filter out those that are not enabled
			return list.filter(item => item.status)
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		}
	}
}
</script>

<style scoped>

</style>
